import {
  getGlobalParamsListItemLabel,
  getCommonParamsItemLabel
} from '@/utils/common'

export const contentTableConfig = {
  propList: [
    {
      prop: 'name',
      label: 'file.ota-name'
    },
    {
      prop: 'author',
      label: 'file.author'
    },
    {
      prop: 'company',
      label: 'file.company'
    },
    {
      prop: 'modelId',
      label: 'file.model-type',
      formatter: (row) => {
        return getGlobalParamsListItemLabel(row, 'terminalModel', {
          isNeedPreFix: false
        })
      }
    },
    {
      prop: 'status',
      label: 'general.status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      prop: 'options',
      label: 'param.param-operation',
      width: '400',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
