import {
  getCommonParamsOptions,
  getGlobalParamsOptionsAsync
} from '@/utils/common'

export const searchFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.ota-name'
    },
    {
      field: 'author',
      type: 'input',
      label: 'file.author'
    },
    {
      field: 'company',
      type: 'input',
      label: 'file.company'
    },
    {
      field: 'modelId',
      type: 'select',
      label: 'file.model-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('terminalModel', {
          isNeedPreFix: false
        })
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status')
    },
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange',
        format: 'YYYY-MM-DD',
        valueFormat: 'YYYY-MM-DD'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
